// TravelViewer.js
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import ZeppelinModel from './ZeppelinModel';

const TravelViewer = ({ onExit }) => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const zeppelinRef = useRef(null);
  const [sceneInstance] = useState(new THREE.Scene());
  const cloudPlanes = useRef([]);

  useEffect(() => {
    const scene = sceneInstance;

    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(100, 10, 100);
    camera.lookAt(0, 0, 0);

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: false });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = false;
    controls.enabled = true;

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 20, 10);
    scene.add(directionalLight);

    // Skybox
    const loader = new THREE.CubeTextureLoader();
    loader.setPath('/assets/skybox/');
    loader.load(
      ['px2.jpg', 'nx2.jpg', 'py2.jpg', 'ny2.jpg', 'pz2.jpg', 'nz2.jpg'],
      (texture) => {
        scene.background = texture;
        console.log('✅ Skybox loaded successfully');
      },
      undefined,
      (err) => {
        console.error('❌ Error loading skybox:', err);
      }
    );

    // Cloud texture planes
    const textureLoader = new THREE.TextureLoader();
    const CLOUD_COUNT = 30;
    const SAFE_ZONE = { x: 400, y: 50, z: 400 };
    textureLoader.load(
      '/assets/clouds/cloud1.png',
      (texture) => {
        for (let i = 0; i < CLOUD_COUNT; i++) {
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            opacity: 0.5,
            depthWrite: false,
            side: THREE.DoubleSide,
          });

          const x = Math.random() * 800 - 400;
          const y = Math.random() * 40 - 20;
          const z = Math.random() * 800 - 400;

          if (
            Math.abs(x) > SAFE_ZONE.x / 2 ||
            Math.abs(y) > SAFE_ZONE.y / 2 ||
            Math.abs(z) > SAFE_ZONE.z / 2
          ) {
            const geometry = new THREE.PlaneGeometry(420, 520);
            const cloud = new THREE.Mesh(geometry, material.clone());
            cloud.position.set(x, y, z);
            cloud.rotation.y = Math.random() * Math.PI * 2;
            scene.add(cloud);
            cloudPlanes.current.push(cloud);
          }
        }
      },
      undefined,
      (err) => {
        console.error('❌ Error loading cloud texture:', err);
      }
    );

    // Animation
    const clock = new THREE.Clock();
    const animate = () => {
      const delta = clock.getDelta();
      const elapsed = clock.getElapsedTime();

      if (zeppelinRef.current) {
        zeppelinRef.current.position.x += delta * 5;

        const zeppelin = zeppelinRef.current;

        // Camera offset behind and above zeppelin
        const cameraOffset = new THREE.Vector3(-100, -20, 100);
        const targetPosition = new THREE.Vector3().copy(zeppelin.position).add(cameraOffset);
        camera.position.lerp(targetPosition, 0.0025);

        // Look at zeppelin by updating OrbitControls target
        controls.target.copy(zeppelin.position);
        controls.update();
      }

      // Clouds
      cloudPlanes.current.forEach((cloud, index) => {
        cloud.position.x += Math.sin(elapsed * 0.3 + index) * 0.005;
        //cloud.rotation.z += 0.0005;
      });

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
    };
  }, [sceneInstance]);

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <button
        onClick={onExit}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 10,
          padding: '10px 20px',
          backgroundColor: '#000',
          color: '#fff',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        Exit Travel View
      </button>
      <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
      <ZeppelinModel scene={sceneInstance} onLoad={(model) => (zeppelinRef.current = model)} />
    </div>
  );
};

export default TravelViewer;
